import { FC, useEffect, useState } from 'react';
import { Link, Typography, useTheme } from "@mui/material";
import { Flexbox } from '../components/utils/Flexbox';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import { IApiInfos } from '../interfaces';
import { useQuery } from '@apollo/client';
import { GQL_GET_API_INFOS } from '../graphql/Queries';

interface IProps { }

export const Footer: FC<IProps> = (props) => {

    const webVersion = "1.1.2"
    const apiExpectedVersion = "1.0.1"

    const [apiInfos, setApiInfos] = useState<IApiInfos>({
        apiVersion: "?",
        codeName: "?",
        coreVersion: "?",
        date: "?",
        environment: "?",
    })

    const theme = useTheme()

    // Pour ne pas refresh :
    const { data } = useQuery(GQL_GET_API_INFOS)

    useEffect(() => {
        if (data) {
            setApiInfos({
                apiVersion: data.getApiInfos.apiVersion,
                codeName: data.getApiInfos.codeName,
                coreVersion: data.getApiInfos.coreVersion,
                date: data.getApiInfos.date,
                environment: data.getApiInfos.environment,
            })
        }
    }, [data])


    return (
        <Flexbox sx={{
            width: "100%",
            justifyContent: "space-around",

            backgroundColor: theme.palette.custom1.main,
            py: 2,
            flexDirection: { xs: "column", sm: "row" },
            alignItems: { xs: "center", sm: "flex-start" },
        }}>
            <Flexbox direction="column">
                <Typography
                    sx={{
                        fontSize: { xs: 18, sm: 38 },
                        fontWeight: "bold",
                    }}
                >
                    Adresse
                </Typography>
                <Typography>
                    30, rue Rodier
                    <br />
                    97410 Saint-Pierre
                    <br />
                    Ile de la Réunion
                </Typography>
            </Flexbox>
            <Flexbox direction="column">
                <Typography
                    sx={{
                        fontSize: { xs: 18, sm: 38 },
                        fontWeight: "bold",
                    }}
                >
                    Horaires
                </Typography>
                <Typography>
                    Lun.-ven : 11h30 – 14h30
                    <br />
                    Ven. : à partir de 18h00
                    <br />
                    <Typography
                        component="span"
                        title={`V ${webVersion} - core: ${apiInfos.coreVersion}(${apiInfos.codeName} - ${apiInfos.environment} - ${apiInfos.date}) - api: ${apiInfos.apiVersion}(expected: ${apiExpectedVersion})`}
                    >
                        Fermé le samedi & Dimanche
                    </Typography>
                </Typography>
            </Flexbox>
            <Flexbox direction="column">
                <Typography
                    sx={{
                        fontSize: { xs: 18, sm: 38 },
                        fontWeight: "bold",
                    }}
                >
                    Nous contacter
                </Typography>
                <Typography>
                    <Typography
                        component="a"
                        href="mailto:contact@lemazeniene.com"
                        sx={{
                            color: theme.palette.text.primary,
                            ":hover": {
                                cursor: "pointer",
                                textDecoration: "underline",
                                color: theme.palette.secondary.main,
                            },
                        }}>
                        contact@lemazeniene.com
                    </Typography>
                    <br />
                    06.92.66.88.32
                </Typography>
                <Flexbox sx={{
                    width: "100%",
                    justifyContent: "center",
                }}>
                    <Link href="https://www.instagram.com/le_mazeniene/" target="_blank">
                        <InstagramIcon
                            sx={{
                                fontSize: 30,
                                // borderStyle: "solid",
                                // borderWidth: 1,
                                // borderRadius: "50%",
                                p: .4,
                                mx: 1,
                                ":hover": {
                                    cursor: "pointer",
                                    color: theme.palette.secondary.main,
                                }
                            }}
                        />
                    </Link>
                    <Link href="https://www.facebook.com/profile.php?id=61552403543324" target="_blank">
                        <FacebookIcon
                            sx={{
                                fontSize: 30,
                                // borderStyle: "solid",
                                // borderWidth: 1,
                                // borderRadius: "50%",
                                p: .4,
                                mx: 1,
                                ":hover": {
                                    cursor: "pointer",
                                    color: theme.palette.secondary.main,
                                }
                            }}
                        />
                    </Link>
                </Flexbox>
            </Flexbox>
        </Flexbox>
    )
}


