import { FC } from 'react';
import { Box, Typography, useTheme } from "@mui/material";
import { Flexbox } from '../components/utils/Flexbox';
import { EnumBuildMode } from '../enums';
import { hexToRgba } from '../utils/helpers';

interface IProps {
    buildMode?: EnumBuildMode,
}

export const DescriptionSection: FC<IProps> = (props) => {

    const theme = useTheme()

    return (
        <Box sx={{
            width: "100%",
            backgroundColor: theme.palette.custom2.main,
            pb: 4,
        }}>
            <Flexbox direction='column'>
                {/* Mobile */}
                <Box
                    component="img"
                    src={`${process.env.PUBLIC_URL}/images/accueil/homepage_picture_mobile.webp`}
                    sx={{
                        display: { xs: "block", sm: "none" },
                        width: "100%",
                    }}
                    loading='lazy'
                />

                {/* Desktop */}
                <Box
                    component="img"
                    src={`${process.env.PUBLIC_URL}/images/accueil/homepage_picture_desktop.webp`}
                    sx={{
                        display: { xs: "none", sm: "block" },
                        width: "100%",
                    }}
                    loading='lazy'
                />

                {
                    props.buildMode && <>
                        <Flexbox sx={{
                            backgroundColor: hexToRgba(theme.palette.gris3.main, "0.9"),
                            py: 1,
                            px: 2,
                            borderRadius: 2,
                            mt: { xs: -16, sm: -60, xl: -80 },
                            mb: { xs: 16, sm: 60, xl: 80 },


                        }}>
                            <Typography sx={{
                                fontSize: { xs: 16, sm: 46 },
                                color: "white",
                                fontFamily: theme.typography.tertiary,
                                fontWeight: "bold",
                            }}>
                                nou lé rouver mèm si le site web lé toujours pa pré alors vien a zot !
                            </Typography>
                        </Flexbox>
                    </>
                }
            </Flexbox>
        </Box>
    )
}


